import { types, getParent } from 'mobx-state-tree';
import Event from './Event';

const times = (function makeAllTimes(stepM) {
  const startH = 0;
  const endH = 23;
  const times = [];
  for (let h = startH; h <= endH; h++) {
    for (let m = 0; m < 60; m += stepM) {
      times.push(`${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`);
    }
  }
  return times;
})(5);
const weeks = (function makeAllWeeks(start, end) {
  const weeks = [];
  for (let w = start; w <= end; w++) {
    weeks.push({
      id: 'week-' + w,
      number: w,
      label: w === 1 ? w + ' week' : w + ' weeks',
    });
  }
  return weeks;
})(0, 156);

export default types
  .model('EventManagerGrid', {
    per_page: 8,
    page: 1,
    event: types.optional(types.maybeNull(types.reference(Event)), null),
  })
  .views((self) => ({
    get times() {
      return times;
    },
    get weeks() {
      const { page, per_page } = self;
      let from = 0;
      let to = 0;
      if (per_page === 1) {
        from = (page - 1) * per_page;
        to = page * per_page;
      }
      if (page === 1) {
        from = 0;
        to = per_page;
      } else {
        from = (page - 1) * (per_page / 2);
        to = (page + 1) * (per_page / 2);
      }
      return weeks.slice(from, to);
    },
    collapsedTimes(events) {
      const collapsed = [];
      const finalCollapsed = [];
      for (let event of events) {
        let index = times.indexOf(event.startFrom);
        if (index === -1) continue;
        // include one before and 4 after
        for (let i = index; i < times.length - 2 && i < index + 4; i++) {
          if (collapsed.includes(times[i])) {
            continue;
          }
          collapsed.push(times[i]);
        }
      }
      // now go through collapsed times and whenever there is a jump in minutes add ...
      for (let i = 0; i < collapsed.length - 1; i++) {
        let time = collapsed[i];
        let nextTime = collapsed[i + 1];
        let t = time.split(':').map((t) => parseInt(t, 10));
        let nt = nextTime.split(':').map((t) => parseInt(t, 10));
        let tMin = t[0] * 60 + t[1];
        let ntMin = nt[0] * 60 + nt[1];
        // if jump is more than 5 minutes...
        finalCollapsed.push(time);
        if (Math.abs(tMin - ntMin) > 5) {
          finalCollapsed.push('...');
        }
      }
      return finalCollapsed;
    },
  }))
  .actions((self) => ({
    prevPage() {
      self.page = Math.max(self.page - 1, 1);
    },
    nextPage() {
      const { page, per_page } = self;
      const maxPage = Math.round(156 / per_page) * 2;
      self.page = Math.min(page + 1, maxPage);
    },
    closeEventView() {
      self.page = Math.ceil(self.page / 8);
      self.event = null;
      self.per_page = 8;
    },
    openWeekEvent(week, event) {
      if (typeof week === 'number') {
        week = weeks.find((w) => w.number === week);
      }
      const openedEvent =
        event ||
        getParent(self).events.find((e) => {
          return e.isEventInWeek(week);
        });
      if (openedEvent) {
        self.per_page = 1;
        self.page = weeks.indexOf(week) * 2 + 1;
        self.event = openedEvent.id;
      }
    },
  }));

import { flow, types } from 'mobx-state-tree';
import firebase from 'helpers/firebase';
import Event from './Event';
import moment from 'moment-timezone';

const EventSchedule = types.model('EventSchedule', {
  loading: false,
  loaded: false,
  schedule: types.array(types.compose('Event', Event, types.model({ id: types.string }))),
});

export default types
  .model('UserScheduleHistory', {
    currentUserID: types.optional(types.string, ''),
    currentLittleOneID: types.optional(types.string, ''),
    currentDate: new Date().toISOString().split('T').shift(),
    currentScheduleHistory: types.optional(EventSchedule, {}),
    ignoreTrackerEvents: types.array(types.string),
  })
  .volatile((self) => ({
    extras: null,
    littleOnes: null,
    trackerEvents: null,
    error: null,
  }))
  .views((self) => ({
    get httpsAdminUserScheduleHistory() {
      return firebase.functions().httpsCallable('httpsAdminUserScheduleHistory');
    },
    get littleOnesName() {
      return self.littleOnes?.Name || '';
    },
    get littleOnesAge() {
      return self.extras?.ageTextLong || '';
    },
    formatTime(seconds, timezone) {
      return moment.unix(seconds).tz(timezone).format('HH:mm');
    },
  }))
  .actions((self) => ({
    loadCurrentDefaultScheduleHistory: flow(function* () {
      try {
        self.error = null;
        self.currentScheduleHistory.loaded = false;
        self.currentScheduleHistory.loading = true;

        const result = yield self.httpsAdminUserScheduleHistory({
          uid: self.currentUserID,
          littleOneID: self.currentLittleOneID,
          date: self.currentDate,
          ignoreTrackerEvents: self.ignoreTrackerEvents,
        });

        self.extras = result.data.schedule.extras;
        self.littleOnes = result.data.schedule.littleOne;

        self.currentScheduleHistory.schedule = result.data.schedule.events || [];

        const trackerEvents = (result.data.trackerEvents || []).map((data) => ({
          ...data,
          includeEvent: !self.ignoreTrackerEvents.find((i) => i === data.id),
        }));
        self.trackerEvents = trackerEvents;
        self.currentScheduleHistory.loaded = true;
      } catch (error) {
        console.error(error.message);
        self.error = error.message;
      } finally {
        self.currentScheduleHistory.loading = false;
      }
    }),
    setCurrentIDs(uid, loID) {
      self.currentDate = new Date().toISOString().split('T').shift();
      self.currentUserID = uid;
      self.currentLittleOneID = loID;
    },
    setCurrentDate: flow(function* (date) {
      if (date === self.currentDate) return;
      self.currentDate = new Date(date).toISOString().split('T').shift();
      self.ignoreTrackerEvents = [];

      yield self.loadCurrentDefaultScheduleHistory();
    }),
    setIgnoreTrackerEvent: flow(function* (eventID) {
      const updatedEvents = self.trackerEvents.map((data) => ({
        ...data,
        includeEvent: data.id === eventID ? !data.includeEvent : data.includeEvent,
      }));
      self.ignoreTrackerEvents = updatedEvents.filter((data) => !data.includeEvent).map((data) => data.id);
      self.trackerEvents = updatedEvents;

      yield self.loadCurrentDefaultScheduleHistory();
    }),
  }));
